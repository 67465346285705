import { Buffer } from "buffer";
import process from "process";
import React from "react";
import ReactDOM from "react-dom/client";
import { ContextProvider } from "./SocketContext";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import configureStore from "./services/store";
import "./styles/index.css";
import App from "./components/app/App";

window.Buffer = Buffer;
window.process = process;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <GoogleOAuthProvider clientId="836909669526-6485q0i3v8rb2ijhcktlrk5beoh6ffne.apps.googleusercontent.com">
    <React.StrictMode>
      <Provider store={configureStore}>
        <BrowserRouter>
          <ContextProvider>
            <App />
          </ContextProvider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
