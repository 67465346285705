import {
  IAppointment,
  IDatedText,
  INotification,
  ITimeRange,
  TActionReport,
  TPrescriptionForm,
} from "../types/types";
import chatIcon from "../images/messages_icon_blue.svg";
import forumIcon from "../images/forum_icon.svg";
import calendarIcon from "../images/calendar_icon.svg";
import { IContactUsTheme } from "./categories";
import { StylesConfig } from "react-select";
import {
  TQuestionnaireEntry,
  TQuestionnaireQuestion,
  TQuestionOption,
  TRRMListEntry,
} from "../services/types/user.types";

export const BASE_URL = "https://api-test.bpreven.com/";
export const BASE_URL_TEST = "http://localhost:7241/";
export const SOCKET_SERVER_URL = "wss://test.dgtracking.co.il/ChatApp/ws";
export const testUserEmail = "admin@bpreven.com";
export const testUserEmail2 = "elena@bpreven.com";

export const mobileWidth = "(max-width: 600px)";
export const tabletWidth = "(max-width:1200px)";

export const phonePrefixOptions = [
  "050",
  "051",
  "052",
  "053",
  "054",
  "055",
  "057",
  "058",
];
export const therapistPOCQuestionnaireName = "שאלון POC טרפיסט";
export const customerService = "customerService";

export const israeliMobileRegExp = /^(05\d|9725\d)([-]?)\d{7}$/;

export enum appointmentOptions {
  Cancel = "cancel",
  Update = "update",
  Create = "create",
}

export const week = [
  "יום א'",
  "יום ב'",
  "יום ג'",
  "יום ד'",
  "יום ה'",
  "יום ו'",
  "יום ש'",
];
export const rangesExample: ITimeRange[] = [
  {
    start: "8:00",
    end: "12:30",
    statusId: 1,
  },
  {
    start: "13:30",
    end: "15:30",
    statusId: 1,
  },
];
export const weekdays: {
  [key: number | string]: { value: string; title: string };
} = {
  1: { value: "sunday", title: "יום א'" },
  2: { value: "monday", title: "יום ב'" },
  3: { value: "tuesday", title: "יום ג'" },
  4: { value: "wednesday", title: "יום ד'" },
  5: { value: "thursday", title: "יום ה'" },
  6: { value: "friday", title: "יום ו'" },
};

export const hoursRange: string[] = [
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
];
export const minutesRange: string[] = ["00", "10", "20", "30", "40", "50"];
export const increment = (
  array: string[],
  currentValue: string,
  type: string
) => {
  const [hours, minutes] = currentValue.split(":");
  if (type === "h") {
    const index = array.indexOf(hours);
    const nextValue = array[(index + 1) % array.length];
    return `${nextValue}:${minutes}`;
  } else if (type === "m") {
    const index = array.indexOf(minutes ?? "00");
    const nextValue = array[(index + 1) % array.length];
    return `${hours}:${nextValue}`;
  }
  const index = array.indexOf(currentValue);
  return array[(index - 1 + array.length) % array.length];
};
export const decrement = (
  array: string[],
  currentValue: string,
  type: string
) => {
  const [hours, minutes] = currentValue.split(":");
  if (type === "h") {
    const index = array.indexOf(hours);
    const nextValue = array[(index - 1 + array.length) % array.length];
    return `${nextValue}:${minutes}`;
  } else if (type === "m") {
    const index = array.indexOf(minutes ?? "00");
    const nextValue = array[(index - 1 + array.length) % array.length];
    return `${hours}:${nextValue}`;
  }
  const index = array.indexOf(currentValue);
  return array[(index - 1 + array.length) % array.length];
};

export const newTimeRange = {
  start: `${hoursRange[0]}:${minutesRange[0]}`,
  end: `${hoursRange[0]}:${minutesRange[0]}`,
  statusId: 1,
};
export const notificationsExample: INotification[] = [
  { unread: true, text: "פניה חדשה מד״ר נאמן", section: "chat" },
  { unread: true, text: "הופקה עבורך חשבונית חדשה", section: "profile" },
  { unread: true, text: "תגובה לשרשור שפתחת בפורום", section: "chat" },
  { unread: false, text: "פניה חדשה מנחום, המדריך", section: "chat" },
  { unread: false, text: "התקבל מרשם חדש", section: "treatment" },
];

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);
export const mainPageTexts: IDatedText[] = [
  {
    id: 1,
    date: today,
    text: "שלום @NAME , במסך יוצגו לכם מספר הנחיות מהנהלת מקשיבים, יש לעקוב אחר העדכונים החדשים כאן. ככל שיש לכם שאלה מקצועית או טכנית, אתם מוזמנים לפנות בוואצאפ למספר 054-2044915. (במקרים דחופים, יש להתקשר ולא לחכות למענה בהודעה) ככל ומדובר בתקלה טכנית יש לפנות למייל הזה: Support@bpreven.com (המענה יינתן תוך 2 ימי עסקים)",
  },
  {
    id: 2,
    date: today,
    text: "שלום @NAME, להלן הנחיה בעניין נוכחות במשמרות: כל יחידה של משמרת הינה לפי השיבוצים שנקבעו לכם על ידי רכזת שיבוצים. כל שינוי בשיבוץ חייב להיות בתיאום מלא עם רכזת שיבוץ. כל פנייה לרכזת השיבוץ תעשה אך ורק במייל הבא: mitnadvim@makshivim-il.org",
  },
  {
    id: 3,
    date: yesterday,
    text: "שלום @NAME, אנחנו נמצאים כרגע בתקופה שמוגדרת 'פוסט חירום', לפי מה שאנחנו רואים יש כעת עלייה בכמות הפניות למוקד הסיוע. אנחנו מבקשים מכם להיות בתשומת לב מלאה לפניות, ולעשות השתדלות לקחת פניות שלא טופלו, על מנת לאפשר טיפול מהיר ככל הניתן. \n",
  },
  {
    id: 4,
    date: yesterday,
    text: "שלום @NAME, להלן הנחיות לגבי טופס 'דוח פעולה'. טופס זה הינו ייעודי עבור צוות מטפל, זה יכול להיות מדריכים, סטודנטים, ואנשי מקצוע. אך יש לשים לב, כי את השאלות הבאות ימלאו אך ורק מי שמורשים לכך על פי חוק ועל פי שיקול דעתם המקצועית. כדוגמת: שאלון DSM, ושאלון תרופות. (למען הסר ספק, שאלון DSM יהיו רשאים למלא רק פסיכולוגים קליניים / פסיכיאטרים. ושאלון תרופות, רק פסיכיאטרים בלבד!)\n",
  },
  {
    id: 5,
    date: yesterday,
    text: "שלום @NAME, להלן תזכורת הנחיות בנוגע לנוהל 'מצב חירום'. ככל ועולה חשד לאובדנות, יש לשאול בצורה מפורשת אם יש כוונה לפגיעה עצמית, אם אם יש תכנון ומועד. וככל שעולה חשש לסכנת חיים. יש לשמור על קור רוח, להשאר עם הפונה על הקו, להוציא מידע חיוני כמו מיקום, להגיד למפונה שאנחנו איתו ולא מנתקים את השיחה עד שהוא יהיה במצב בטוח. ככל שיש בן משפחה או אדם קרוב רצוי להזעיק אותו בפלאפון נוסף, במקביל להזעיק את כוחות החירום משטרה ומד''א. להיעזר באנשים סביבכם. לא מנתקים את השיחה עד להגעת כוחות החירום למקום. מומלץ להיעזר בכונן החירום במקשיבים ובקבוצת המתנדבים על מנת לתפעל נוהל חירום.",
  },
];
export interface ICategory {
  value: string;
  title: string;
  img: string;
}

export const therapistLinks: ICategory[] = [
  { value: "messages", img: chatIcon, title: "צ'אט" },
  { value: "forum", img: forumIcon, title: "פורום" },
  {
    value: "calendar",
    img: calendarIcon,
    title: "פגישות",
  },
  // { value: "reports", img: reportsIcon, title: "דוחות" },
];

export const filterOptions = [
  { value: "status", label: "סטטוס" },
  { value: "urgent", label: "דחופים" },
  { value: "today", label: "היום" },
  { value: "week", label: "השבוע" },
  { value: "month", label: "החודש" },
  { value: "date", label: "תאריך מסוים" },
];
export const filterOptionsMain = [
  { value: "rrm", label: "rrm" },
  { value: "today", label: "היום" },
  { value: "week", label: "השבוע" },
  { value: "month", label: "החודש" },
];
export const filterOptionsMainWithStatus = [
  ...filterOptionsMain,
  { value: "status", label: "סטטוס" },
];

export const therapistAppointmentsTest: IAppointment[] = [
  {
    id: 1,
    userName: "צחי חזן",
    userId: "1",
    date: new Date(),
    // color: "#121759",
  },
  {
    id: 2,
    userName: "מעין זולילי",
    userId: "47",
    date: new Date(),
    // color: "#5d3cc7",
  },
  {
    id: 3,
    userName: "אלי צדחך",
    userId: "48",
    date: new Date(),
    // color: "#006cc9",
  },
];

export interface IOption {
  value: string;
  label: string;
  desc?: string;
}
export type TOptionWithDesc = IOption & {
  desc: string;
};

export const reasonVariants: IOption[] = [
  { value: "1", label: "שיחת תמיכה" },
  { value: "2", label: "בדידות" },
  { value: "3", label: "דימוי עצמי" },
  { value: "4", label: "זהות" },
  { value: "5", label: "תגובה לאבדו" },
  { value: "6", label: "זוגיות" },
  { value: "7", label: "קשיים רגשיים" },
  { value: "8", label: "מתחים ומריבות" },
  { value: "9", label: "יחסי הורים-ילדים" },
  { value: "10", label: "אלימות מינית" },
  { value: "11", label: "אלימות פיזית" },
  { value: "12", label: "מיניות" },
  { value: "13", label: "פרידה" },
  { value: "14", label: "לימודים" },
  { value: "15", label: "תעסוקה" },
  { value: "16", label: "צבא" },
  { value: "17", label: "מצב כלכלי" },
  { value: "18", label: "מצב ביטחוני" },
  { value: "19", label: "חרדה" },
  { value: "20", label: "דיכאון" },
  { value: "21", label: "כאב נפשי" },
  { value: "22", label: "טראומה" },
  { value: "23", label: "הפרעות אכילה" },
  { value: "24", label: "התמכרויות" },
  { value: "25", label: "סכיזופרניה" },
  { value: "26", label: "השלכות נפשיות של נכויות" },
];

export const rrmVariants = [
  {
    label: "יש סיכון לאובדנות. יש לדרג:",
    value: "1",
    options: [
      {
        value: "יש סיכון לאובדנות:נמוך",
        label: "נמוך",
        desc: "יש סיכון לאובדנות:",
      },
      {
        value: "יש סיכון לאובדנות:בינוני",
        label: "בינוני ",
        desc: "יש סיכון לאובדנות:",
      },
      {
        value: "יש סיכון לאובדנות:גבוה",
        label: "גבוה",
        desc: "יש סיכון לאובדנות:",
      },
    ],
  },
  {
    label: "אין  סיכון לאובדנות",
    value: "אין  סיכון לאובדנות",
  },
  {
    label: "יש לבצע הערכה מקצועית. יש לדרג דחיפות:",
    options: [
      {
        value: "יש לבצע הערכה מקצועית. דחיפות:נמוך",
        label: "נמוך",
        desc: "יש לבצע הערכה מקצועית. דחיפות:",
      },
      {
        value: "יש לבצע הערכה מקצועית. דחיפות:בינוני",
        label: "בינוני ",
        desc: "יש לבצע הערכה מקצועית. דחיפות:",
      },
      {
        value: "יש לבצע הערכה מקצועית. דחיפות:גבוה",
        label: "גבוה",
        desc: "יש לבצע הערכה מקצועית. דחיפות:",
      },
    ],
  },
];
export const emdVariants = [
  {
    label: "חרדה. יש לדרג רמה:",
    options: [
      { value: "חרדה:נמוך", label: "נמוך", desc: "חרדה: " },
      { value: "חרדה:בינוני", label: "בינוני ", desc: "חרדה: " },
      { value: "חרדה:גבוה", label: "גבוה", desc: "חרדה: " },
    ],
  },
  {
    label: "דיכאון. יש לדרג רמה:",
    options: [
      { value: "דיכאון:נמוך", label: "נמוך", desc: "דיכאון: " },
      { value: "דיכאון:בינוני", label: "בינוני ", desc: "דיכאון: " },
      { value: "דיכאון:גבוה", label: "גבוה", desc: "דיכאון: " },
    ],
  },
  {
    label: "חוסן. יש לדרג רמה:",
    options: [
      { value: "חוסן:נמוך", label: "נמוך", desc: "חוסן: " },
      { value: "חוסן:בינוני", label: "בינוני ", desc: "חוסן: " },
      { value: "חוסן:גבוה", label: "גבוה", desc: "חוסן: " },
    ],
  },
];

export const dsmGroupedOptions = [
  {
    label: "Anxiety disorders",
    options: [
      {
        value: "Separation anxiety disorder",
        label: "Separation anxiety disorder",
      },
      { value: "Specific phobia", label: "Specific phobia" },
      { value: "Social anxiety disorder", label: "Social anxiety disorder" },
      { value: "Panic disorder", label: "Panic disorder" },
      { value: "Agoraphobia", label: "Agoraphobia" },
      {
        value: "Generalized anxiety disorder",
        label: "Generalized anxiety disorder",
      },
      { value: "Selective mutism", label: "Selective mutism" },
    ],
  },
  {
    label: "Dissociative disorders",
    options: [
      {
        value: "Dissociative identity disorder",
        label: "Dissociative identity disorder",
      },
      {
        value: "Dissociative amnesia (psychogenic amnesia)",
        label: "Dissociative amnesia (psychogenic amnesia)",
      },
      {
        value: "Depersonalization-derealization disorder",
        label: "Depersonalization-derealization disorder",
      },
      { value: "Dissociative fugue", label: "Dissociative fugue" },
      {
        value:
          "Dissociative neurological symptom disorder (this includes Psychogenic non-epileptic seizures)",
        label:
          "Dissociative neurological symptom disorder (this includes Psychogenic non-epileptic seizures)",
      },
      {
        value: "Other specified dissociative disorder (OSDD)",
        label: "Other specified dissociative disorder (OSDD)",
      },
      {
        value: "Unspecified dissociative disorder",
        label: "Unspecified dissociative disorder",
      },
      { value: "Ganser syndrome", label: "Ganser syndrome" },
    ],
  },
  {
    label: "Mood disorders",
    options: [
      { value: "Depressive disorders", label: "Depressive disorders" },
      { value: "Depression (mood)", label: "Depression (mood)" },
      { value: "Anhedonia", label: "Anhedonia" },
      {
        value: "Disruptive mood dysregulation disorder",
        label: "Disruptive mood dysregulation disorder",
      },
      {
        value: "Major depressive disorder",
        label: "Major depressive disorder",
      },
      { value: "Dysthymia", label: "Dysthymia" },
      {
        value: "Premenstrual dysphoric disorder",
        label: "Premenstrual dysphoric disorder",
      },
      { value: "Psychotic depression", label: "Psychotic depression" },
      {
        value: "Seasonal affective disorder (SAD)",
        label: "Seasonal affective disorder (SAD)",
      },
      { value: "Atypical depression", label: "Atypical depression" },
      { value: "Postpartum depression", label: "Postpartum depression" },
      {
        value: "Pervasive refusal syndrome",
        label: "Pervasive refusal syndrome",
      },
      {
        value: "Unspecified depressive disorder",
        label: "Unspecified depressive disorder",
      },
    ],
  },
  {
    label: "Bipolar disorders",
    options: [
      { value: "Bipolar I disorder", label: "Bipolar I disorder" },
      { value: "Bipolar II disorder", label: "Bipolar II disorder" },
      {
        value: "Bipolar disorder not otherwise specified",
        label: "Bipolar disorder not otherwise specified",
      },
      { value: "Cyclothymia", label: "Cyclothymia" },
      { value: "Hypomania", label: "Hypomania" },
    ],
  },
  {
    label: "Trauma and stressor related disorders",
    options: [
      {
        value: "Reactive attachment disorder",
        label: "Reactive attachment disorder",
      },
      {
        value: "Disinhibited social engagement disorder",
        label: "Disinhibited social engagement disorder",
      },
      {
        value: "Post-traumatic stress disorder (PTSD)",
        label: "Post-traumatic stress disorder (PTSD)",
      },
      {
        value: "Post-traumatic embitterment disorder (PTED)",
        label: "Post-traumatic embitterment disorder (PTED)",
      },
      {
        value: "False memory syndrome",
        label: "False memory syndrome",
      },
      {
        value: "Acute stress disorder",
        label: "Acute stress disorder",
      },
      {
        value: "Adjustment disorder",
        label: "Adjustment disorder",
      },
      {
        value: "Complex post-traumatic stress disorder (C-PTSD)",
        label: "Complex post-traumatic stress disorder (C-PTSD)",
      },
      {
        value: "Prolonged grief disorder",
        label: "Prolonged grief disorder",
      },
    ],
  },
  {
    label: "Neuro-developmental disorders",
    options: [
      {
        value: "Intellectual disability",
        label: "Intellectual disability",
      },
      {
        value: "Language disorder",
        label: "Language disorder",
      },
      {
        value: "Sensory processing disorder",
        label: "Sensory processing disorder",
      },
      {
        value: "Speech sound disorder",
        label: "Speech sound disorder",
      },
      {
        value: "Stuttering",
        label: "Stuttering",
      },
      {
        value: "Social communication disorder",
        label: "Social communication disorder",
      },
      {
        value: "Pervasive developmental disorder",
        label: "Pervasive developmental disorder",
      },
      {
        value: "Auditory processing disorder",
        label: "Auditory processing disorder",
      },
      {
        value: "Communication disorder",
        label: "Communication disorder",
      },
      {
        value:
          "Autism spectrum disorder (formally a category that included Asperger syndrome, Classic autism and Rett syndrome)",
        label:
          "Autism spectrum disorder (formally a category that included Asperger syndrome, Classic autism and Rett syndrome)",
      },
      {
        value: "Attention deficit hyperactivity disorder (ADHD)",
        label: "Attention deficit hyperactivity disorder (ADHD)",
      },
      {
        value: "Developmental coordination disorder",
        label: "Developmental coordination disorder",
      },
      {
        value: "Tourette syndrome",
        label: "Tourette syndrome",
      },
      {
        value: "Down syndrome",
        label: "Down syndrome",
      },
      {
        value: "Tic disorder",
        label: "Tic disorder",
      },
      {
        value: "Dyslexia",
        label: "Dyslexia",
      },
      {
        value: "Dyscalculia",
        label: "Dyscalculia",
      },
      {
        value: "Dysgraphia",
        label: "Dysgraphia",
      },
      {
        value: "Nonverbal learning Disorder (NVLD, NLD)",
        label: "Nonverbal learning Disorder (NVLD, NLD)",
      },
    ],
  },
  {
    label: "Sleep-wake disorders",
    options: [
      {
        value: "Insomnia (including chronic insomnia and short-term insomnia)",
        label: "Insomnia (including chronic insomnia and short-term insomnia)",
      },
      {
        value: "Hypersomnia",
        label: "Hypersomnia",
      },
      {
        value: "Idiopathic hypersomnia",
        label: "Idiopathic hypersomnia",
      },
      {
        value: "Kleine–Levin syndrome",
        label: "Kleine–Levin syndrome",
      },
      {
        value: "Insufficient sleep syndrome",
        label: "Insufficient sleep syndrome",
      },
      {
        value: "Narcolepsy",
        label: "Narcolepsy",
      },
      {
        value: "Restless legs syndrome",
        label: "Restless legs syndrome",
      },
      {
        value: "Sleep apnea",
        label: "Sleep apnea",
      },
      {
        value: "Night terrors (sleep terrors)",
        label: "Night terrors (sleep terrors)",
      },
      {
        value: "Exploding head syndrome",
        label: "Exploding head syndrome",
      },
      {
        value: "Parasomnias",
        label: "Parasomnias",
      },
      {
        value: "Nightmare disorder",
        label: "Nightmare disorder",
      },
      {
        value: "Rapid eye movement sleep behavior disorder",
        label: "Rapid eye movement sleep behavior disorder",
      },
      {
        value: "Confusional arousals",
        label: "Confusional arousals",
      },
      {
        value: "Sleepwalking",
        label: "Sleepwalking",
      },
      {
        value: "Hypnagogic hallucinations",
        label: "Hypnagogic hallucinations",
      },
      {
        value: "Hypnopompic hallucinations",
        label: "Hypnopompic hallucinations",
      },
      {
        value: "Circadian rhythm sleep disorder",
        label: "Circadian rhythm sleep disorder",
      },
      {
        value: "Circadian rhythm sleep disorder",
        label: "Circadian rhythm sleep disorder",
      },
      {
        value: "Delayed sleep phase disorder",
        label: "Delayed sleep phase disorder",
      },
      {
        value: "Advanced sleep phase disorder",
        label: "Advanced sleep phase disorder",
      },
      {
        value: "Irregular sleep–wake rhythm",
        label: "Irregular sleep–wake rhythm",
      },
      {
        value: "Non-24-hour sleep–wake disorder",
        label: "Non-24-hour sleep–wake disorder",
      },
      {
        value:
          "Circadian rhythm sleep-wake disorder caused by irregular work shifts",
        label:
          "Circadian rhythm sleep-wake disorder caused by irregular work shifts",
      },
      {
        value: "Jet lag",
        label: "Jet lag",
      },
    ],
  },
  {
    label: "Neuro-cognitive disorders",
    options: [
      {
        value: "Agnosia",
        label: "Agnosia",
      },
      {
        value: "Delirium",
        label: "Delirium",
      },
      {
        value: "Dementia",
        label: "Dementia",
      },
      {
        value: "Traumatic brain injury",
        label: "Traumatic brain injury",
      },
      {
        value: "HIV-associated neurocognitive disorder (HAND)",
        label: "HIV-associated neurocognitive disorder (HAND)",
      },
      {
        value: "Amnesia",
        label: "Amnesia",
      },
      {
        value: "Chronic traumatic encephalopathy",
        label: "Chronic traumatic encephalopathy",
      },
    ],
  },
  {
    label: "Substance related disorders",
    options: [
      {
        value: "Substance use disorder",
        label: "Substance use disorder",
      },
      {
        value:
          "Substance-induced disorder (Substance-induced psychosis, Substance-induced delirium, Substance-induced mood disorder)",
        label:
          "Substance-induced disorder (Substance-induced psychosis, Substance-induced delirium, Substance-induced mood disorder)",
      },
      {
        value: "Substance intoxication",
        label: "Substance intoxication",
      },
      {
        value: "Substance withdrawal",
        label: "Substance withdrawal",
      },
      {
        value: "Substance dependence",
        label: "Substance dependence",
      },
    ],
  },
  {
    label: "Disorders due to use of alcohol",
    options: [
      {
        value: "Alcohol use disorder",
        label: "Alcohol use disorder",
      },
      {
        value: "Alcoholic hallucinosis",
        label: "Alcoholic hallucinosis",
      },
      {
        value: "Alcohol withdrawal",
        label: "Alcohol withdrawal",
      },
      {
        value: "Harmful pattern of use of alcohol",
        label: "Harmful pattern of use of alcohol",
      },
    ],
  },
  {
    label: "Disorders due to use of cannabis",
    options: [
      {
        value: "Cannabis use disorder",
        label: "Cannabis use disorder",
      },
      {
        value: "Cannabis dependence",
        label: "Cannabis dependence",
      },
      {
        value: "Cannabis intoxication",
        label: "Cannabis intoxication",
      },
      {
        value: "Harmful pattern of use of cannabis",
        label: "Harmful pattern of use of cannabis",
      },
      {
        value: "Cannabis withdrawal",
        label: "Cannabis withdrawal",
      },
      {
        value: "Cannabis-induced delirium",
        label: "Cannabis-induced delirium",
      },
      {
        value: "Cannabis-induced psychosis",
        label: "Cannabis-induced psychosis",
      },
      {
        value: "Cannabis-induced mood disorder",
        label: "Cannabis-induced mood disorder",
      },
      {
        value: "Cannabis-induced anxiety",
        label: "Cannabis-induced anxiety",
      },
    ],
  },
  {
    label: "Disorders due to use of synthetic cannabinoids",
    options: [
      {
        value: "Episode of harmful use of synthetic cannabinoids",
        label: "Episode of harmful use of synthetic cannabinoids",
      },
      {
        value: "Harmful pattern of use of synthetic cannabinoids",
        label: "Harmful pattern of use of synthetic cannabinoids",
      },
      {
        value: "Synthetic cannabinoid dependence",
        label: "Synthetic cannabinoid dependence",
      },
      {
        value: "Synthetic cannabinoid intoxication",
        label: "Synthetic cannabinoid intoxication",
      },
      {
        value: "Synthetic cannabinoids withdrawal",
        label: "Synthetic cannabinoids withdrawal",
      },
      {
        value: "Synthetic cannabinoids induced delirium",
        label: "Synthetic cannabinoids induced delirium",
      },
      {
        value: "Synthetic cannabinoids induced psychotic disorder",
        label: "Synthetic cannabinoids induced psychotic disorder",
      },
      {
        value: "Synthetic cannabinoids induced mood disorder",
        label: "Synthetic cannabinoids induced mood disorder",
      },
      {
        value: "Synthetic cannabinoids induced anxiety",
        label: "Synthetic cannabinoids induced anxiety",
      },
    ],
  },
  {
    label: "Disorders due to use of opioids",
    options: [
      {
        value: "Episode of harmful use of Opioids",
        label: "Episode of harmful use of Opioids",
      },
      {
        value: "Harmful pattern of use of Opioids",
        label: "Harmful pattern of use of Opioids",
      },
      {
        value: "Opioid dependence",
        label: "Opioid dependence",
      },
      {
        value: "Opioid intoxication",
        label: "Opioid intoxication",
      },
      {
        value: "Opioids withdrawal",
        label: "Opioids withdrawal",
      },
      {
        value: "Opioids induced delirium",
        label: "Opioids induced delirium",
      },
      {
        value: "Opioids induced psychotic disorder",
        label: "Opioids induced psychotic disorder",
      },
      {
        value: "Opioids induced mood disorder",
        label: "Opioids induced mood disorder",
      },
      {
        value: "Opioids induced anxiety",
        label: "Opioids induced anxiety",
      },
    ],
  },
  {
    label: "Disorders due to use of sedative, hypnotic or anxiolytic",
    options: [
      {
        value: "Episode of harmful use of Sedative, hypnotic or anxiolytic",
        label: "Episode of harmful use of Sedative, hypnotic or anxiolytic",
      },
      {
        value: "Harmful pattern of use of Sedative, hypnotic or anxiolytic",
        label: "Harmful pattern of use of Sedative, hypnotic or anxiolytic",
      },
      {
        value: "Sedative, hypnotic or anxiolytic dependence",
        label: "Sedative, hypnotic or anxiolytic dependence",
      },
      {
        value: "Sedative, hypnotic or anxiolytic intoxication",
        label: "Sedative, hypnotic or anxiolytic intoxication",
      },
      {
        value: "Sedative, hypnotic or anxiolytic withdrawal",
        label: "Sedative, hypnotic or anxiolytic withdrawal",
      },
      {
        value: "Sedative, hypnotic or anxiolytic induced delirium",
        label: "Sedative, hypnotic or anxiolytic induced delirium",
      },
      {
        value: "Sedative, hypnotic or anxiolytic induced psychotic disorder",
        label: "Sedative, hypnotic or anxiolytic induced psychotic disorder",
      },
      {
        value: "Sedative, hypnotic or anxiolytic induced mood disorder",
        label: "Sedative, hypnotic or anxiolytic induced mood disorder",
      },
      {
        value: "Sedative, hypnotic or anxiolytic induced anxiety",
        label: "Sedative, hypnotic or anxiolytic induced anxiety",
      },
      {
        value:
          "Amnestic disorder due to use of sedatives, hypnotics or anxiolytics",
        label:
          "Amnestic disorder due to use of sedatives, hypnotics or anxiolytics",
      },
      {
        value: "Dementia due to use of sedatives, hypnotics or anxiolytics",
        label: "Dementia due to use of sedatives, hypnotics or anxiolytics",
      },
    ],
  },
  {
    label: "Disorders due to use of Cocaine",
    options: [
      {
        value: "Episode of harmful use of Cocaine",
        label: "Episode of harmful use of Cocaine",
      },
      {
        value: "Harmful pattern of use of Cocaine",
        label: "Harmful pattern of use of Cocaine",
      },
      {
        value: "Cocaine dependence",
        label: "Cocaine dependence",
      },
      {
        value: "Cocaine intoxication",
        label: "Cocaine intoxication",
      },
      {
        value: "Cocaine withdrawal",
        label: "Cocaine withdrawal",
      },
      {
        value: "Cocaine induced delirium",
        label: "Cocaine induced delirium",
      },
      {
        value: "Cocaine induced psychotic disorder",
        label: "Cocaine induced psychotic disorder",
      },
      {
        value: "Cocaine induced mood disorder",
        label: "Cocaine induced mood disorder",
      },
      {
        value: "Cocaine induced anxiety",
        label: "Cocaine induced anxiety",
      },
      {
        value: "Cocaine induced OCD",
        label: "Cocaine induced OCD",
      },
      {
        value: "Cocaine induced impulse control disorder",
        label: "Cocaine induced impulse control disorder",
      },
    ],
  },
  {
    label: "Disorders due to use of Amphetamines",
    options: [
      {
        value: "Episode of harmful use of Amphetamines",
        label: "Episode of harmful use of Amphetamines",
      },
      {
        value: "Harmful pattern of use of Amphetamines",
        label: "Harmful pattern of use of Amphetamines",
      },
      {
        value: "Amphetamines dependence",
        label: "Amphetamines dependence",
      },
      {
        value: "Amphetamines intoxication",
        label: "Amphetamines intoxication",
      },
      {
        value: "Amphetamines withdrawal",
        label: "Amphetamines withdrawal",
      },
      {
        value: "Amphetamines induced delirium",
        label: "Amphetamines induced delirium",
      },
      {
        value: "Amphetamines induced psychotic disorder",
        label: "Amphetamines induced psychotic disorder",
      },
      {
        value: "Amphetamines induced mood disorder",
        label: "Amphetamines induced mood disorder ",
      },
      {
        value: "Amphetamines induced anxiety",
        label: "Amphetamines induced anxiety",
      },
      {
        value: "Amphetamines induced OCD",
        label: "Amphetamines induced OCD",
      },
      {
        value: "Amphetamines induced impulse control disorder",
        label: "Amphetamines induced impulse control disorder",
      },
    ],
  },
  {
    label: "Disorders due to use of synthetic cathinone",
    options: [
      {
        value: "Episode of harmful use of synthetic cathinone",
        label: "Episode of harmful use of synthetic cathinone",
      },
      {
        value: "Harmful pattern of use of synthetic cathinone",
        label: "Harmful pattern of use of synthetic cathinone",
      },
      {
        value: "Synthetic cathinone dependence",
        label: "Synthetic cathinone dependence",
      },
      {
        value: "Synthetic cathinone intoxication",
        label: "Synthetic cathinone intoxication",
      },
      {
        value: "Synthetic cathinone withdrawal",
        label: "Synthetic cathinone withdrawal",
      },
      {
        value: "Synthetic cathinone induced delirium",
        label: "Synthetic cathinone induced delirium",
      },
      {
        value: "Synthetic cathinone induced psychotic disorder",
        label: "Synthetic cathinone induced psychotic disorder",
      },
      {
        value: "Synthetic cathinone induced mood disorder",
        label: "Synthetic cathinone induced mood disorder",
      },
      {
        value: "Synthetic cathinone induced anxiety",
        label: "Synthetic cathinone induced anxiety",
      },
      {
        value: "Synthetic cathinone induced OCD",
        label: "Synthetic cathinone induced OCD",
      },
      {
        value: "Synthetic cathinone induced impulse control disorder",
        label: "Synthetic cathinone induced impulse control disorder",
      },
    ],
  },
  {
    label: "Disorders due to use of caffeine",
    options: [
      {
        value: "Episode of harmful use of caffeine",
        label: "Episode of harmful use of caffeine",
      },
      {
        value: "Harmful pattern of use of caffeine",
        label: "Harmful pattern of use of caffeine",
      },
      {
        value: "Caffeine intoxication",
        label: "Caffeine intoxication",
      },
      {
        value: "Caffeine withdrawal",
        label: "Caffeine withdrawal",
      },
      {
        value: "Caffeine induced anxiety disorder",
        label: "Caffeine induced anxiety disorder",
      },
      {
        value: "Caffeine-induced sleep disorder",
        label: "Caffeine-induced sleep disorder",
      },
    ],
  },
  {
    label: "Disorders due to use of hallucinogens",
    options: [
      {
        value: "Episode of harmful use of hallucinogens",
        label: "Episode of harmful use of hallucinogens",
      },
      {
        value: "Harmful pattern of use of hallucinogens",
        label: "Harmful pattern of use of hallucinogens",
      },
      {
        value: "Hallucinogens dependence",
        label: "Hallucinogens dependence",
      },
      {
        value: "Hallucinogen induced delirium",
        label: "Hallucinogen induced delirium",
      },
      {
        value: "Hallucinogens induced psychotic disorder",
        label: "Hallucinogens induced psychotic disorder",
      },
      {
        value: "Hallucinogens induced anxiety disorder",
        label: "Hallucinogens induced anxiety disorder",
      },
      {
        value: "Hallucinogens induced mood disorder",
        label: "Hallucinogens induced mood disorder",
      },
      {
        value: "Hallucinogen persisting perception disorder",
        label: "Hallucinogen persisting perception disorder",
      },
    ],
  },
  {
    label: "Disorders due to use of nicotine",
    options: [
      {
        value: "Episode of harmful use of nicotine",
        label: "Episode of harmful use of nicotine",
      },
      {
        value: "Harmful pattern of use of nicotine",
        label: "Harmful pattern of use of nicotine",
      },
      {
        value: "nicotine intoxication",
        label: "nicotine intoxication",
      },
      {
        value: "nicotine withdrawal",
        label: "nicotine withdrawal",
      },
      {
        value: "Nicotine dependence",
        label: "Nicotine dependence",
      },
    ],
  },
  {
    label: "Disorders due to use of volatile inhalants",
    options: [
      {
        value: "Episode of harmful use of volatile inhalants",
        label: "Episode of harmful use of volatile inhalants",
      },
      {
        value: "Harmful pattern of use of volatile inhalants",
        label: "Harmful pattern of use of volatile inhalants",
      },
      {
        value: "Opioid dependence",
        label: "Opioid dependence",
      },
      {
        value: "Opioid intoxication",
        label: "Opioid intoxication",
      },
      {
        value: "Volatile inhalants withdrawal",
        label: "Volatile inhalants withdrawal",
      },
      {
        value: "Volatile inhalants induced delirium",
        label: "Volatile inhalants induced delirium",
      },
      {
        value: "Volatile inhalants induced psychotic disorder",
        label: "Volatile inhalants induced psychotic disorder",
      },
      {
        value: "Volatile inhalants induced mood disorder",
        label: "Volatile inhalants induced mood disorder",
      },
      {
        value: "Volatile inhalants induced anxiety",
        label: "Volatile inhalants induced anxiety",
      },
    ],
  },
  {
    label:
      "Disorders due to use of dissociative drugs including ketamine and phencyclidine (PCP)",
    options: [
      {
        value:
          "Episode of harmful use of dissociative drugs including ketamine and phencyclidine [PCP]",
        label:
          "Episode of harmful use of dissociative drugs including ketamine and phencyclidine [PCP]",
      },
      {
        value:
          "Harmful pattern of use of dissociative drugs including ketamine and phencyclidine [PCP]",
        label:
          "Harmful pattern of use of dissociative drugs including ketamine and phencyclidine [PCP]",
      },
      {
        value:
          "Dissociative drugs including ketamine and phencyclidine [PCP] dependence",
        label:
          "Dissociative drugs including ketamine and phencyclidine [PCP] dependence",
      },
      {
        value:
          "Dissociative drugs including ketamine and phencyclidine [PCP] intoxication",
        label:
          "Dissociative drugs including ketamine and phencyclidine [PCP] intoxication",
      },
      {
        value:
          "Dissociative drugs including ketamine and phencyclidine [PCP] withdrawal",
        label:
          "Dissociative drugs including ketamine and phencyclidine [PCP] withdrawal",
      },
      {
        value:
          "Dissociative drugs including ketamine and phencyclidine [PCP] induced delirium",
        label:
          "Dissociative drugs including ketamine and phencyclidine [PCP] induced delirium",
      },
      {
        value:
          "Dissociative drugs including ketamine and phencyclidine [PCP] induced psychotic disorder",
        label:
          "Dissociative drugs including ketamine and phencyclidine [PCP] induced psychotic disorder",
      },
      {
        value:
          "Dissociative drugs including ketamine and phencyclidine [PCP]induced mood disorder",
        label:
          "Dissociative drugs including ketamine and phencyclidine [PCP]induced mood disorder",
      },
      {
        value:
          "Dissociative drugs including ketamine and phencyclidine [PCP] induced anxiety",
        label:
          "Dissociative drugs including ketamine and phencyclidine [PCP] induced anxiety",
      },
    ],
  },
  {
    label: "Non-substance related disorder",
    options: [
      {
        value: "Addictive personality",
        label: "Addictive personality",
      },
      {
        value: "Gambling disorder",
        label: "Gambling disorder",
      },
      {
        value: "Video game addiction",
        label: "Video game addiction",
      },
      {
        value: "Internet addiction disorder",
        label: "Internet addiction disorder",
      },
      {
        value: "Sexual addiction",
        label: "Sexual addiction",
      },
      {
        value: "Food addiction",
        label: "Food addiction",
      },
      {
        value: "Exercise addiction",
        label: "Exercise addiction",
      },
      {
        value: "Addiction to social media",
        label: "Addiction to social media",
      },
      {
        value: "Pornography addiction",
        label: "Pornography addiction",
      },
      {
        value: "Shopping addiction",
        label: "Shopping addiction",
      },
      {
        value: "Workaholic",
        label: "Workaholic",
      },
    ],
  },
  {
    label: "Paraphilias",
    options: [
      {
        value: "Voyeuristic disorder",
        label: "Voyeuristic disorder",
      },
      {
        value: "Exhibitionistic disorder",
        label: "Exhibitionistic disorder",
      },
      {
        value: "Frotteuristic disorder",
        label: "Frotteuristic disorder",
      },
      {
        value: "Pedophilia",
        label: "Pedophilia",
      },
      {
        value: "Compulsive sexual behaviour disorder",
        label: "Compulsive sexual behaviour disorder",
      },
      {
        value: "Erotic target location error",
        label: "Erotic target location error",
      },
      {
        value: "Sexual masochism disorder",
        label: "Sexual masochism disorder",
      },
      {
        value: "Sexual sadism disorder",
        label: "Sexual sadism disorder",
      },
      {
        value: "Fetishistic disorder",
        label: "Fetishistic disorder",
      },
      {
        value: "Transvestic disorder",
        label: "Transvestic disorder",
      },
      {
        value: "Other specified paraphilic disorder",
        label: "Other specified paraphilic disorder",
      },
    ],
  },
  {
    label: "Somatic symptom related disorders",
    options: [
      {
        value: "Hypochondriasis",
        label: "Hypochondriasis",
      },
      {
        value: "Cyberchondria",
        label: "Cyberchondria",
      },
      {
        value: "Somatization disorder",
        label: "Somatization disorder",
      },
      {
        value: "Conversion disorder (Functional Neurological Symptom Disorder)",
        label: "Conversion disorder (Functional Neurological Symptom Disorder)",
      },
      {
        value: "Factitious disorder imposed on self (munchausen syndrome)",
        label: "Factitious disorder imposed on self (munchausen syndrome)",
      },
      {
        value: "Factitious disorder imposed on another (munchausen by proxy)",
        label: "Factitious disorder imposed on another (munchausen by proxy)",
      },
      {
        value: "Pain disorder",
        label: "Pain disorder",
      },
      {
        value: "Medically unexplained physical symptoms",
        label: "Medically unexplained physical symptoms",
      },
    ],
  },
  {
    label: "Sexual dysfunctions",
    options: [
      {
        value: "Sexual dysfunction",
        label: "Sexual dysfunction",
      },
      {
        value: "Delayed ejaculation",
        label: "Delayed ejaculation",
      },
      {
        value: "Erectile dysfunction",
        label: "Erectile dysfunction",
      },
      {
        value: "Anorgasmia",
        label: "Anorgasmia",
      },
      {
        value: "Vaginismus",
        label: "Vaginismus",
      },
      {
        value: "Male hypoactive sexual desire disorder",
        label: "Male hypoactive sexual desire disorder",
      },
      {
        value: "Female sexual arousal disorder",
        label: "Female sexual arousal disorder",
      },
      {
        value: "Premature ejaculation",
        label: "Premature ejaculation",
      },
      {
        value: "Dyspareunia",
        label: "Dyspareunia",
      },
    ],
  },
  {
    label: "Elimination disorders",
    options: [
      {
        value: "Enuresis (Involuntary urination)",
        label: "Enuresis (Involuntary urination)",
      },
      {
        value: "Nocturnal enuresis",
        label: "Nocturnal enuresis",
      },
      {
        value: "Encopresis (Involuntary defecation)",
        label: "Encopresis (Involuntary defecation)",
      },
    ],
  },
  {
    label: "Feeding and eating disorders",
    options: [
      {
        value: "Pica (disorder)",
        label: "Pica (disorder)",
      },
      {
        value: "Rumination syndrome",
        label: "Rumination syndrome",
      },
      {
        value: "Avoidant/restrictive food intake disorder",
        label: "Avoidant/restrictive food intake disorder",
      },
      {
        value: "Anorexia nervosa",
        label: "Anorexia nervosa",
      },
      {
        value: "Binge eating disorder",
        label: "Binge eating disorder",
      },
      {
        value: "Bulimia nervosa",
        label: "Bulimia nervosa",
      },
      {
        value: "Other specified feeding or eating disorder (OSFED)",
        label: "Other specified feeding or eating disorder (OSFED)",
      },
      {
        value: "Purging disorder",
        label: "Purging disorder",
      },
      {
        value: "Diabulimia",
        label: "Diabulimia",
      },
      {
        value: "Night eating syndrome",
        label: "Night eating syndrome",
      },
      {
        value: "Orthorexia nervosa",
        label: "Orthorexia nervosa",
      },
    ],
  },
  {
    label: "Disruptive impulse-control, and conduct disorders",
    options: [
      {
        value: "Intermittent explosive disorder",
        label: "Intermittent explosive disorder",
      },
      {
        value: "Oppositional defiant disorder",
        label: "Oppositional defiant disorder",
      },
      {
        value: "Conduct disorder",
        label: "Conduct disorder",
      },
      {
        value: "Antisocial personality disorder",
        label: "Antisocial personality disorder",
      },
      {
        value: "Pyromania",
        label: "Pyromania",
      },
      {
        value: "Kleptomania",
        label: "Kleptomania",
      },
      {
        value: "Disruptive mood dysregulation disorder",
        label: "Disruptive mood dysregulation disorder",
      },
    ],
  },
  {
    label: "Obsessive-compulsive and related disorders",
    options: [
      {
        value: "Obsessive–compulsive disorder (OCD)",
        label: "Obsessive–compulsive disorder (OCD)",
      },
      {
        value: "Body dysmorphic disorder",
        label: "Body dysmorphic disorder",
      },
      {
        value: "Body integrity dysphoria",
        label: "Body integrity dysphoria",
      },
      {
        value: "Compulsive hoarding",
        label: "Compulsive hoarding",
      },
      {
        value: "Trichotillomania",
        label: "Trichotillomania",
      },
      {
        value: "Excoriation disorder (Skin picking disorder)",
        label: "Excoriation disorder (Skin picking disorder)",
      },
      {
        value: "Body-focused repetitive behavior disorder",
        label: "Body-focused repetitive behavior disorder",
      },
      {
        value: "Olfactory reference syndrome",
        label: "Olfactory reference syndrome",
      },
      {
        value: "Primarily obsessional obsessive-compulsive disorder",
        label: "Primarily obsessional obsessive-compulsive disorder",
      },
      {
        value: "Hoarding disorder",
        label: "Hoarding disorder",
      },
    ],
  },
  {
    label: "Schizophrenia spectrum and other psychotic disorders",
    options: [
      {
        value: "Delusional disorder",
        label: "Delusional disorder",
      },
      {
        value: "Schizophrenia",
        label: "Schizophrenia",
      },
      {
        value: "Schizoaffective disorder",
        label: "Schizoaffective disorder",
      },
      {
        value: "Schizophreniform disorder",
        label: "Schizophreniform disorder",
      },
      {
        value: "Brief psychotic disorder",
        label: "Brief psychotic disorder",
      },
    ],
  },
  {
    label: "Personality disorders",
    options: [
      {
        value: "Paranoid personality disorder",
        label: "Paranoid personality disorder",
      },
      {
        value: "Schizoid personality disorder",
        label: "Schizoid personality disorder",
      },
      {
        value: "Schizotypal personality disorder",
        label: "Schizotypal personality disorder",
      },
      {
        value: "Antisocial personality disorder",
        label: "Antisocial personality disorder",
      },
      {
        value: "Borderline personality disorder",
        label: "Borderline personality disorder",
      },
      {
        value: "Histrionic personality disorder",
        label: "Histrionic personality disorder",
      },
      {
        value: "Narcissistic personality disorder",
        label: "Narcissistic personality disorder",
      },
      {
        value: "Avoidant personality disorder",
        label: "Avoidant personality disorder",
      },
      {
        value: "Dependent personality disorder",
        label: "Dependent personality disorder",
      },
      {
        value: "Obsessive–compulsive personality disorder",
        label: "Obsessive–compulsive personality disorder",
      },
    ],
  },
  {
    label: "Other",
    options: [
      {
        value:
          "Gender dysphoria (also known as gender integrity disorder or gender incongruence, there are different categorizations for children and non-children in the ICD-11)",
        label:
          "Gender dysphoria (also known as gender integrity disorder or gender incongruence, there are different categorizations for children and non-children in the ICD-11)",
      },
      {
        value:
          "Medication-induced movement disorders and other adverse effects of medication",
        label:
          "Medication-induced movement disorders and other adverse effects of medication",
      },
      {
        value: "Catatonia",
        label: "Catatonia",
      },
    ],
  },
];

export const medications = [
  "Abilify (aripiprazole)",
  "Adderall (mixed amphetamine salts)",
  "Ambien (zolpidem)",
  "Anafranil (clomipramine)",
  "Aricept (donepezil)",
  "Ativan (lorazepam)",
  "Asendin (amoxapine)",
  "Azstarys (Serdexmethylphenidate/Dexmethylphenidate)",
  "Benperidol",
  "Buspar (buspirone)",
  "Belsomra (Suvorexant)",
  "Celexa (citalopram)",
  "Centrax (prazepam)",
  "clobazam (Frisium, Onfi, Tapclob, Urbanol)",
  "clorazepate (Novo-Clopate, Tranxene)",
  "chlordiazepoxide (Librium)",
  "Clozaril (clozapine)",
  "Concerta (methylphenidate)",
  "Contrave (naltrexone/bupropion)",
  "Cymbalta (duloxetine)",
  "Depakote (valproic acid/sodium valproate)",
  "Desyrel (trazodone)",
  "Desoxyn (methamphetamine hydrochloride)",
  "Dexedrine (dextroamphetamine sulfate)",
  "disulfiram (Antabuse)",
  "doxepin (Aponal, Quitaxon, Sinequan)",
  "Effexor and Effexor XR (venlafaxine)",
  "Elavil (amitriptyline)",
  "estazolam (Prosom, Eurodin)",
  "Fetzima (levomilnacipran)",
  "Fycompa (perampanel)",
  "Geodon (ziprasidone)",
  "Gabitril (tiagabine)",
  "Haldol (haloperidol)",
  "Imovane (zopiclone)",
  "Inderal (propranolol)",
  "Intuniv (Guanfacine)",
  "Invega (paliperidone)",
  "Keppra (levetiracetam)",
  "Klonopin (clonazepam)",
  "Lamictal (lamotrigine)",
  "Latuda (lurasidone)",
  "Lexapro (escitalopram)",
  "Librium (chlordiazepoxide)",
  "Lithium (Lithobid, Eskalith)",
  "Loxam (escitalopram)",
  "Lunesta (eszopiclone)",
  "Luvox (fluvoxamine)",
  "Loxitane (loxapine)",
  "Melatonin",
  "Minipress (Prazosin)",
  "Naltrexone (ReVia)",
  "Neurontin (gabapentin)",
  "Norapramin (Desipramine)",
  "Nuplazid (pimavanserin)",
  "Pamelor",
  "Parnate (tranylcypromine)",
  "Paxil (paroxetine)",
  "Phenelzine (Nardil)",
  "Pimozide (Orap)",
  "Pristiq (desvenlafaxine)",
  "Prolixin (fluphenazine)",
  "Provigil (modafinil)",
  "Prozac (fluoxetine)",
  "Phenobarbital (Luminal)",
  "Remeron (mirtazapine)",
  "Restoril (temazepam)",
  "Risperdal (risperidone)",
  "Ritalin (methylphenidate)",
  "Reminyl (galantamine)",
  "ReVia (naltrexone)",
  "Rexulti (brexpiprazole)",
  "Saphris (asenapine)",
  "Serax (oxazepam)",
  "Serentil (mesoridazine)",
  "Seroquel and Seroquel XR (quetiapine)",
  "Sonata (zaleplon)",
  "Spravato (esketamine)",
  "Stelazine (trifluoperazine)",
  "Strattera (atomoxetine)",
  "Suboxone (Buprenorphine/naloxone)",
  "Tenex (Guanfacine)",
  "Thorazine",
  "Tofranil (imipramine)",
  "Topamax (topiramate)",
  "Trileptal (oxcarbazepine)",
  "Trintellix (vortioxetine)",
  "Tegretol (carbamazepine)",
  "Trilafon (Perphenazine)",
  "Valium (diazepam)",
  "Vistaril (hydroxyzine)",
  "Vyvanse (lisdexamfetamine)",
  "Viibryd (vilazodone)",
  "Vivactil (Protriptyline)",
  "Vraylar (cariprazine)",
  "Wellbutrin SR or XL (bupropion)",
  "Zyban",
  "Xanax (alprazolam)",
  "Zoloft (sertraline)",
  "Zulresso (brexanolone)",
  "Zyprexa (olanzapine)",
];
export const recommendations = [
  {
    label: "לתאם פגישת הערכה עם:",
    options: [
      {
        value: "פסיכיאטר/ית",
        label: "פסיכיאטר/ית",
        desc: "לתאם פגישת הערכה עם: ",
      },
      {
        value: "פסיכולוג/ית קליני מומחה",
        label: "פסיכולוג/ית קליני מומחה",
        desc: "לתאם פגישת הערכה עם: ",
      },
      {
        value: "פסיכולוג/ית התפתחותי מומחה",
        label: "פסיכולוג/ית התפתחותי מומחה",
        desc: "לתאם פגישת הערכה עם: ",
      },
      {
        value: "פסיכולוג/ית שיקומי מומחה",
        label: "פסיכולוג/ית שיקומי מומחה",
        desc: "לתאם פגישת הערכה עם: ",
      },
      {
        value: "פסיכולוג/ית חינוכי מומחה",
        label: "פסיכולוג/ית חינוכי מומחה",
        desc: "לתאם פגישת הערכה עם: ",
      },
    ],
  },
  {
    label: "לתאם פגישת טיפול עם:",
    options: [
      {
        value: "פסיכיאטר/ית",
        label: "פסיכיאטר/ית",
        desc: "לתאם פגישת טיפול עם: ",
      },
      {
        value: "פסיכולוג/ית קליני מומחה",
        label: "פסיכולוג/ית קליני מומחה",
        desc: "לתאם פגישת טיפול עם: ",
      },
      {
        value: "פסיכולוג/ית התפתחותי מומחה",
        label: "פסיכולוג/ית התפתחותי מומחה",
        desc: "לתאם פגישת טיפול עם: ",
      },
      {
        value: "פסיכולוג/ית שיקומי מומחה",
        label: "פסיכולוג/ית שיקומי מומחה",
        desc: "לתאם פגישת טיפול עם: ",
      },
      {
        value: "תומך/ת טיפול",
        label: "תומך/ת טיפול",
        desc: "לתאם פגישת טיפול עם: ",
      },
    ],
  },
  {
    label: "סטינג פגישות מומלץ:",
    options: [
      {
        value: "1 לשבוע",
        label: "1 לשבוע",
        desc: "סטינג פגישות מומלץ: ",
      },
      {
        value: "2 לשבוע",
        label: "2 לשבוע",
        desc: "סטינג פגישות מומלץ: ",
      },
      {
        value: "3 לשבוע",
        label: "3 לשבוע",
        desc: "סטינג פגישות מומלץ: ",
      },
      {
        value: "1 לחודש",
        label: "1 לחודש",
        desc: "סטינג פגישות מומלץ: ",
      },
      {
        value: "2 לחודש",
        label: "2 לחודש",
        desc: "סטינג פגישות מומלץ: ",
      },
    ],
  },
  {
    label: "לתאם פגישות מעקב:",
    options: [
      {
        value: "פסיכיאטר/ית",
        label: "פסיכיאטר/ית",
        desc: "לתאם פגישות מעקב עם:  ",
      },
      {
        value: "פסיכולוג/ית קליני מומחה",
        label: "פסיכולוג/ית קליני מומחה",
        desc: "לתאם פגישות מעקב עם:  ",
      },
      {
        value: "פסיכולוג/ית התפתחותי מומחה",
        label: "פסיכולוג/ית התפתחותי מומחה",
        desc: "לתאם פגישות מעקב עם:  ",
      },
      {
        value: "פסיכולוג/ית שיקומי מומחה",
        label: "פסיכולוג/ית שיקומי מומחה",
        desc: "לתאם פגישות מעקב עם:  ",
      },
      {
        value: "תומך/ת טיפול",
        label: "תומך/ת טיפול",
        desc: "לתאם פגישות מעקב עם:  ",
      },
    ],
  },
];

export const treatmentWays: IOption[] = [
  { value: "צאט", label: "צאט" },
  { value: "וידאו", label: "וידאו" },
  { value: "טלפון", label: "טלפון" },
  { value: "טופס בקשה", label: "טופס בקשה" },
];
export const durationOptions: IOption[] = [
  { value: "30", label: "0-30" },
  { value: "45", label: "45" },
  { value: "50", label: "50" },
  { value: "60", label: "60" },
  { value: "75", label: "75" },
];

export const drugOptions: IOption[] = [
  { value: "Celexa", label: "Celexa" },
  { value: "Prozac", label: "Prozac" },
  { value: "Lexapro", label: "Lexapro" },
  { value: "Luvox", label: "Luvox" },
  { value: "Paxil", label: "Paxil" },
  { value: "Zoloft", label: "Zoloft" },
  { value: "Effexor", label: "Effexor" },
  { value: "Trentellix", label: "Trentellix" },
  { value: "Wellbutrin", label: "Wellbutrin" },
  { value: "Marplan", label: "Marplan" },
  { value: "Remeron", label: "Remeron" },
  { value: "Sertraline", label: "Sertraline" },
  { value: "Dosulepin", label: "Dosulepin" },
  { value: "Duloxetine", label: "Duloxetine" },
  { value: "Escitalopram", label: "Escitalopram " },
  { value: "Lamotrigine", label: "Lamotrigine" },
  { value: "Mirtazapine", label: "Mirtazapine" },
  { value: "Paroxetine", label: "Paroxetine" },
  { value: "Trazodone", label: "Trazodone" },
  { value: "Temazepam", label: "Temazepam" },
  { value: "Lithium", label: "Lithium" },
  { value: "Fluoxetine (prozac)", label: "Fluoxetine (prozac)" },
  { value: "Nocturno", label: "Nocturno" },
];
export const medicineApplicationOptions: IOption[] = [
  { value: "tablet", label: "טבליות TAB" },
  { value: "liquid", label: "נוזל" },
  { value: "caps", label: "כמוסה" },
];
export const medicineDosageOptions: IOption[] = [
  { value: "mg", label: "mg" },
  { value: "ml", label: "ml" },
];
export const auxiliaryWords = ["אני", "לא", "יכול", ",", " ", "", "רוצה"];
export enum ContactUsSearchOptions {
  Input = "input",
  List = "list",
}
const textExample: string =
  "על מנת לשדרג מנוי, יש להיכנס לאזור “הפרופיל שלי” - “הגדרות” - “תכנית המנוי שלנו” ושם לבחור במנוי הרצוי. " +
  "יש המון רעש בחוץ, בסביבה הקרובה והרחוקה שלנו, אבל הרעש המשמעותי ביותר מגיע מתוכנו דרך המחשבות שלנו. " +
  "למעשה, האויב הגדול ביותר שלנו לא נמצא בחוץ אלא בפנים והוא משפיע על איך אנחנו חווים את המציאות בחוץ." +
  " על מנת לשדרג מנוי, יש להיכנס לאזור “הפרופיל שלי” - “הגדרות” - “תכנית המנוי שלנו” ושם לבחור במנוי הרצוי. " +
  "יש המון רעש בחוץ, בסביבה הקרובה והרחוקה שלנו, אבל הרעש המשמעותי ביותר מגיע מתוכנו דרך המחשבות שלנו. " +
  "למעשה, האויב הגדול ביותר שלנו לא נמצא בחוץ אלא בפנים והוא משפיע על איך אנחנו חווים את המציאות בחוץ." +
  " יש המון רעש בחוץ, בסביבה הקרובה והרחוקה שלנו, אבל הרעש המשמעותי ביותר מגיע מתוכנו דרך המחשבות שלנו." +
  " למעשה, האויב הגדול ביותר שלנו לא נמצא בחוץ אלא בפנים והוא משפיע על איך אנחנו חווים את המציאות בחוץ.";
const textExample2: string =
  "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה. לפמעט מוסן מנת.";

export const contactUsList: IContactUsTheme[] = [
  {
    title: "שדרוג מנוי",
    text: textExample,
  },
  {
    title: "סוגי מנויים",
    text: textExample2,
  },
  {
    title: "מידע כללי",
    text: textExample2,
  },
  {
    title: "הרשמה",
    text: textExample,
  },
  {
    title: "קבע פגישה עם מומחה",
    text: textExample2,
  },
  {
    title: "מרשמים ומסמכים",
    text: textExample2,
  },
];
export const techSupportThemes = [
  "הודעות",
  "הוספת שם",
  "כללי",
  "איזור האישי",
  "לשנות הגדרות",
];
export const actionsReportInitialState: TActionReport = {
  duration: undefined,
  emd: undefined,
  reason: undefined,
  dsm: undefined,
  medicine: undefined,
  recommendations: undefined,
  rrm: undefined,
  treatmentWay: undefined,
};
export const disorders = [
  "דיכאון",
  "התפתחות",
  "התמכרות",
  "הפרעות חרדה",
  "חרדה כללית",
  "חרדה חברתית",
  "התקפי חרדה",
  "סכיזופרניה",
  "הפרעת אכילה",
  "נדודי שינה",
  "פאניקה",
  "אגורפוביה",
  "מצב רוח",
  "תסמונת קדם-וסתית",
  "הפרעות סומטופורמיות",
  "היפוכונדריה",
  "הפרעות אישיות",
  "הפרעות הסתגלות",
  "התנהגות",
];

export const prescriptionFormInitialState: TPrescriptionForm = {
  medicine: "",
  days: "",
  applicationOptions: "",
  dosage: "",
  dosageValue: "mg",
  numberTimesADay: "",
  instructions: "",
};

export const selectCustomStyles: StylesConfig<IOption, false> = {
  control: (provided) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "24px",
    height: "24px",
  }),

  valueContainer: (provided) => ({
    ...provided,
    height: "24px",
    padding: "0 6px",
  }),

  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "24px",
  }),
};

export const noDataLabel = "אין מידע";
export const recommendationsExample: TOptionWithDesc[] = [
  {
    value: "1",
    label: "אופציה 1",
    desc: 'בדיקות סקר תקופתיות הן הדרך הטובה ביותר לשמור על הבריאות. הן מאפשרות זיהוי מוקדם של בעיות פוטנציאליות ושל מחלות, מאפשרות טיפול מוקדם ומשפרות באופן משמעותי את סיכויי ההחלמה. איגדנו עבורך סל מרוכז של בדיקות, שסוקרות את כל כולך. יום אחד בו תוכלי לדאוג להיבטים השונים של הבריאות שלך ולסמן "וי" גדול, לפחות לתקופה הקרובה.',
  },
  {
    value: "2",
    label: "אופציה 2",
    desc: "אנחנו מבינים כמה את עמוסה, ולכן ריכזנו עבורך את כל בדיקות הסקר התקופתיות המומלצות ליום אחד. ",
  },
  {
    value: "3",
    label: "אופציה 3",
    desc: "מגוון רחב של בדיקות ביום אחד ובמקום אחד- כולל פאפ, ממוגרפיה, צפיפות עצם, כירורגיית שד, ייעוץ תזונתי, רופא/ת עור לאבחון סרטן עור ועוד - בבתי חולים מובילים בפריסה ארצית.",
  },
  {
    value: "4",
    label: "אופציה 4",
    desc: "מחלת האוסטיאופורוזיס גורמת לבריחת סידן, ולשברים בעצמות, המלווים לעתים בסיבוכים לא פשוטים. במקרים שנדרש הבדיקה תעשה לנשים מעל גיל 50 ולנשים בסיכון גבוה לאוסטאופורוזיס מגיל 40.",
  },
];

export type TLabeledQuestionOption = IOption & TQuestionOption;
export type TGrouppedQuestionsOptions = {
  label: string;
  options: TLabeledQuestionOption[];
};

export const transformServerOptions = (
  optionsArray: TQuestionOption[],
  count: number | null,
  min: null | number
): Array<TGrouppedQuestionsOptions | TLabeledQuestionOption> => {
  const groups: Record<string, TGrouppedQuestionsOptions> = {};
  const noGroup: TLabeledQuestionOption[] = [];

  optionsArray.forEach((option) => {
    if (option.GroupId) {
      const groupKey = `group-${option.GroupId}`;
      if (!groups[groupKey]) {
        groups[groupKey] = {
          label: option.Groups ? option.Groups.Name : "Default Group",
          options: [],
        };
      }
      groups[groupKey].options.push({
        ...option,
        count,
        min,
        value: option.Id.toString(),
        label: option.Desc,
      });
    } else {
      noGroup.push({
        ...option,
        count,
        min,
        value: option.Id.toString(),
        label: option.Desc,
      });
    }
  });
  for (const groupKey in groups) {
    if (groups[groupKey].options) {
      groups[groupKey].options.sort((a, b) => a.Seq - b.Seq);
    }
  }
  noGroup.sort((a, b) => a.Seq - b.Seq);
  return [...Object.values(groups), ...noGroup];
};

// Type guard function for TGrouppedQuestionsOptions
export function isTGrouppedQuestionsOptions(
  object: TGrouppedQuestionsOptions | TLabeledQuestionOption
): object is TGrouppedQuestionsOptions {
  return typeof object === "object" && object !== null && "options" in object;
}

export const findLatestQuestionnaire = (
  id: number,
  questionnaires: TQuestionnaireEntry[]
): TQuestionnaireEntry | undefined => {
  if (questionnaires.length === 0) return undefined;
  const temp = questionnaires.filter((q) => q.QuestionnaireId === id);
  return temp.length
    ? temp
        .filter((q) => q.QuestionnaireId === id)
        .reduce((latest, current) => {
          const latestDate = new Date(latest.TimeStamp);
          const currentDate = new Date(current.TimeStamp);
          return currentDate > latestDate ? current : latest;
        })
    : undefined;
};

export function calculateAge(birthDateString: string): number {
  const today = new Date();
  const birthDate = new Date(birthDateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // Check if the birth month hasn't come yet this year, or if it's the same month but the day hasn't come yet
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

export const RRMListDefault: TRRMListEntry[] = [
  {
    Id: 1,
    Title: "A",
    Desc: null,
    Color: "#5d3cc7",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
  {
    Id: 2,
    Title: "B",
    Desc: null,
    Color: "#4f0080",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
  {
    Id: 3,
    Title: "C",
    Desc: null,
    Color: "#fffe02",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
  {
    Id: 4,
    Title: "D",
    Desc: null,
    Color: "#ff7654",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
  {
    Id: 5,
    Title: "E",
    Desc: null,
    Color: "#ff7803",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
  {
    Id: 6,
    Title: "F",
    Desc: null,
    Color: "#cd2701",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
];
export const getSelectNote = (
  min: number | null,
  max: number | null
): string => {
  let text = "";
  if (!min) return text;
  if (min) {
    min === 1
      ? max === 1
        ? (text = "*יש לבחור אפשרות אחת")
        : (text = "*יש לבחור אפשרות אחת לפחות")
      : (text = `*יש לבחור לפחות ${min} אפשרויות`);
  }
  return text;
};
export const getSelectPlaceholder = (
  question: TQuestionnaireQuestion,
  groupId: number | null
): string => {
  const count = question.OptionsCountMax;
  return count === 1
    ? `אפשר לבחור רק אפשרות אחת ${groupId ? "מכל קבוצה" : ""}`
    : `אפשר לבחור עד ${count} אפשרויות`;
};

export function filterLastOfEachGroup(
  options: TLabeledQuestionOption[]
): TLabeledQuestionOption[] {
  if (!options.length || !options[0]?.GroupId) {
    return options;
  } else {
    const groupMap = options.reduce<Record<number, TLabeledQuestionOption>>(
      (acc, item) => {
        // @ts-ignore
        acc[item.GroupId] = item; // Overwrites previous entry with same GroupId with the current item
        return acc;
      },
      {}
    );

    return Object.values(groupMap);
  }
}

export function findMinAndMax(items: TRRMListEntry[] | null): {
  min: number;
  max: number;
} {
  if (!items || items.length === 0) {
    throw new Error("The array is empty");
  }

  let min = items[0].Min;
  let max = items[0].Max;

  for (const item of items) {
    if (item.Min < min) {
      min = item.Min;
    }
    if (item.Max > max) {
      max = item.Max;
    }
  }

  return { min, max };
}

export const isFormValid = (form: {
  [key: string]: {
    isValid: boolean;
  };
}): boolean => {
  return Object.keys(form).every((k) => form[k].isValid);
};

export const INACTIVITY_TIMEOUT = 18 * 60 * 1000; // 18 minutes until logout
export const WARNING_TIMEOUT = 15 * 60 * 1000; // 15 minutes until warning
// export const INACTIVITY_TIMEOUT = 4 * 60 * 1000; // for test
// export const WARNING_TIMEOUT = 1 * 60 * 1000; // for test

export const WARNING_DURATION = 3 * 60 * 1000;
export const formatCountdown = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(
    remainingSeconds
  ).padStart(2, "0")}`;
};

export const formatDateTime = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
};

export const TYPING_CODE = "TYPING_CODE";
export const END_CALL_CODE = "END_CALL_CODE";

export const chatNoteTypes = {
  isTyping: "isTyping",
  text: "text",
} as const;
