import styles from "./calendar.module.css";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import Modal from "../../components/modals/modal/Modal";
import AppointmentCard from "../../components/modals/appointmentCard/appointmentCard";
import { IAppointment } from "../../types/types";
import { therapistAppointmentsTest } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useLazyGetAllAppointmentsQuery } from "../../services/api/user.api";
import { useAppSelector } from "../../services/hooks";
import { calendarAppointmentSelector, therapistIdSelector } from "../../services/selectors/user.selectors";
import { TCalendarAppointment } from "../../services/types/user.types";
function CalendarPage() {
  const navigate = useNavigate();
  const therapistId = useAppSelector(therapistIdSelector)
  const [activeDate, setActiveDate] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [appointmentData, setAppointmentData] = useState<TCalendarAppointment | null>(
    null
  );

  const [getUserAppointments] = useLazyGetAllAppointmentsQuery()
  const userAppointments = useAppSelector(calendarAppointmentSelector)
  const [userAppointmentsByActiveDay, setUserAppointmentsByActiveDay] =
    useState<TCalendarAppointment[]>(
      userAppointments.filter(
        (app) =>  app.appointmentDate.toDateString() === activeDate.toDateString() )
    );
  useEffect(() => {

    setUserAppointmentsByActiveDay(
      userAppointments.filter(
        (app) =>  app.appointmentDate && app.appointmentDate.toDateString() === activeDate.toDateString()
      )

    );
  }, [userAppointments, activeDate]);

  const handleViewChanges = ({ activeStartDate, view }: any) => {
    if (view == "month") {
      getUserAppointments(
        {
          therapistId: therapistId,
          month: activeStartDate.getMonth() + 1,
          year: activeStartDate.getFullYear()
        })
    }
  }
  useEffect(() => {
    getUserAppointments(
      {
        therapistId: therapistId,
        month: activeDate.getMonth() + 1,
        year: activeDate.getFullYear()
      })

  }, [])


  return (
    <section className={styles.myCalendar}>
      <h2 className={styles.myCalendar__title}>הפגישות שלי</h2>
      {openModal && appointmentData && (
        <Modal onClose={() => setOpenModal(false)}>
          <AppointmentCard appointmentData={appointmentData} />
        </Modal>
      )}
      <div className={styles.calendarSection}>
        <button
          className={styles.editAvailabilityBtn}
          onClick={() => navigate("edit")}
        >
          לערוך זמינות שלי
        </button>
        <Calendar
          calendarType={"hebrew"}
          locale={"he"}
          onClickDay={setActiveDate}
          value={activeDate}
          prev2Label={null}
          next2Label={null}
          onActiveStartDateChange={handleViewChanges}
          tileContent={({ date }) => {
            const appointments: TCalendarAppointment[] = userAppointments.filter(
              (ap) => ap.appointmentDate.toDateString() === date.toDateString()
            );
            if (appointments.length) {
              return (
                <div className={styles.circleContainer}>
                  <span className={styles.circle} />
                </div>
              );
            } else {
              return null;
            }
          }}
          className={styles.myCalendar}
        />
        <div className={styles.appointmentsContainer}>
          {userAppointments?.length ? (
            <ul className={styles.appointmentsList}>
              {userAppointmentsByActiveDay.length > 0 ? (
                userAppointmentsByActiveDay.map((a, index) => (
                  <li
                    key={index}
                    className={styles.appointmentsLi}
                    onClick={() => {
                      setAppointmentData(a);
                      setOpenModal(true);
                    }}
                  >
                    <div className={styles.time}>
                      <span
                        className={`${styles.circle} ${styles.circle_big}`}
                      // style={{ background: `${a.color}` }}
                      />
                      <p className={styles.duration}>{a.startTime??`${a.appointmentDate.getHours().toString().padStart(2, '0')}:${a.appointmentDate.getMinutes().toString().padStart(2, '0')}`}</p>
                    </div>
                    <div className={styles.time}>
                      <p className={styles.name}>{a.users?.fullName}</p>
                      <p className={`${styles.name} ${styles.account}`}>
                        מס' משתמש: {a.users?.id}
                      </p>
                    </div>
                  </li>
                ))
              ) : (
                <p className={styles.noAppointmentsText}>אין פגישות ביום הזה</p>
              )}
            </ul>
          ) : (
            <p className={styles.noAppointmentsText}>טרם נקבעו פגישות</p>
          )}
        </div>
      </div>
    </section>
  );
}

export default CalendarPage;
