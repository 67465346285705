import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/auth.slice";
import { authApi } from "../api/auth.api";
import { chatApi } from "../api/chat.api";
import chatReducer from "../slices/chat.slice";

export const rootReducer = combineReducers({
  user: userReducer,
  [authApi.reducerPath]: authApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  chat: chatReducer,
});

export const index = configureStore({
  reducer: rootReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      authApi.middleware,
      chatApi.middleware,
    ]),
  devTools: process.env.NODE_ENV === "development",
  preloadedState: undefined,
});

export type AppDispatch = typeof store.dispatch;
export default store;
