import {
  IAppointment,
  IAppointmentDay,
  IDocumentsToFilter,
  IMessage,
  ITherapist,
} from "../types/types";
import { Dispatch, SetStateAction } from "react";
import { DateObject, Value } from "react-multi-date-picker";
import {
  IChatAppointment,
  IQuestionAnswer,
  TTherapistWithUsers,
} from "../services/types/user.types";
import { IUserStatus } from "../types/ws.types";
import { formatDateTime } from "./constants";

export function getDate(date: string) {
  const messageDate = new Date(date);
  const now = new Date();
  const messageYear = messageDate.getFullYear();
  const messageMonth = messageDate.getMonth();
  const messageDay = messageDate.getDate();
  const yearNow = now.getFullYear();
  const monthNow = now.getMonth();
  const dayNow = now.getDate();
  if (
    messageYear === yearNow &&
    messageMonth === monthNow &&
    messageDay === dayNow
  ) {
    return "היום";
  } else if (
    messageYear === yearNow &&
    messageMonth === monthNow &&
    dayNow - messageDay === 1
  ) {
    return "אתמול";
  }
  return `${messageDay}/${messageMonth}/${messageYear}`;
}

const months = [
  "ינואר",
  "פברואר",
  "מרץ",
  "אפריל",
  "מאי",
  "יוני",
  "יולי",
  "אוגוסט",
  "ספטמבר",
  "אוקטובר",
  "נובמבר",
  "נובמבר",
];
export const getMonth = (date: Date) => {
  const month = date.getMonth();
  return months[month];
};

function formatNumberWithLeadingZero(num: number): string {
  return num < 10 ? `0${num}` : `${num}`;
}

export function getDuration(date: Date, minutesToAdd: number): string {
  const newDate = new Date(date);
  newDate.setMinutes(newDate.getMinutes() + minutesToAdd);
  return `${formatNumberWithLeadingZero(
    date.getHours()
  )}:${formatNumberWithLeadingZero(
    date.getMinutes()
  )}-${formatNumberWithLeadingZero(
    newDate.getHours()
  )}:${formatNumberWithLeadingZero(newDate.getMinutes())}`;
}
export const getTimeFromTimestamp = (date: Date | string) => {
  if (!date) return "";
  if (typeof date === "string") {
    date = new Date(date);
  }
  const timestamp = date.toTimeString().split(":");
  return `${timestamp[0]}:${timestamp[1]}`;
};

export function getPrintDate(date: Date, short?: boolean): string {
  return `${date.getDate()}/${date.getMonth() + 1}/${
    !short ? date.getFullYear() : date.getFullYear().toString().substr(-2)
  }`;
}

export function isExpired(date: Date): boolean {
  const now = new Date();
  return date < now;
}
export function groupAppointmentsByDate(
  appointments: IAppointment[]
): IAppointmentDay[] {
  const groups: any = appointments?.reduce((groups, a) => {
    const date = a.date.toDateString();
    // @ts-ignore
    if (!groups[date]) {
      // @ts-ignore
      groups[date] = [];
    }
    // @ts-ignore
    groups[date].push(a);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  return Object.keys(groups).map((date) => {
    return {
      date,
      appointments: groups[date],
    };
  });
}
export const passwordCheck = (
  value: string
): {
  strength: number;
  val: string;
} => {
  let pwdCheck = 0;
  let validateRegex = ["[A-Z]", "[a-z]", "[0-9]", "\\W"];

  value.length > 5 &&
    validateRegex.forEach((regex) => {
      if (new RegExp(regex).test(value)) {
        pwdCheck += 1;
      }
    });
  switch (pwdCheck) {
    case 2:
      return {
        strength: 2,
        val: "בינוני",
      };
    case 3:
      return {
        strength: 3,
        val: "חזק",
      };

    default:
      return {
        strength: 1,
        val: "חלש",
      };
  }
};

export function lowercaseKeys(obj: object): object {
  return Object.entries(obj).reduce((carry, [key, value]) => {
    // @ts-ignore
    carry[key.charAt(0).toLowerCase() + key.slice(1)] = value;
    return carry;
  }, {});
}

type ChangedValues = Partial<ITherapist>;

export const getChangedValues = (
  values: ITherapist,
  initialValues: ITherapist
): ChangedValues => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = initialValues[key] !== value;

    if (hasChanged) {
      acc[key as keyof ITherapist] = value;
    }

    return acc;
  }, {} as ChangedValues);
};

export function isSameDateAndTime(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate() &&
    date1.getHours() === date2.getHours() &&
    date1.getMinutes() === date2.getMinutes()
  );
}

export const findById = (id: string, array: { id: string }[]) => {
  return array.find((d) => d.id === id);
};
// const filterDocumentsByDate = (dateValue) => {
//   if (dateValue) {
//     setDocumentsToShow(
//         filterByDate(therapistDocuments, dateValue as DateObject)
//     );
//   }
//   // setFiltersVisible(false);
// };
export const isSameDate = (date1: Date, date2: DateObject): boolean => {
  // Convert DateObject from react-calendar to a Date object
  const convertedDate = new Date(date2.year, date2.month.index, date2.day);

  // Check if the dates have the same year, month, and day
  return (
    date1.getFullYear() === convertedDate.getFullYear() &&
    date1.getMonth() === convertedDate.getMonth() &&
    date1.getDate() === convertedDate.getDate()
  );
};

export const filterByDate = <T extends IDocumentsToFilter>(
  array: T[],
  dateValue: Value
) => {
  if (dateValue instanceof DateObject) {
    return array.filter((doc) => isSameDate(doc.issued, dateValue));
  } else return array;
};

export const filterDocuments = <T extends IDocumentsToFilter>(
  filter: string,
  statusFilter: string | undefined,
  array: T[],
  setDocumentsToShow: Dispatch<SetStateAction<T[]>>
) => {
  const today = new Date();
  if (filter === "status" && statusFilter) {
    const filteredDocs = array.filter((doc) => doc.status === statusFilter);
    setDocumentsToShow(filteredDocs);
  } else if (filter === "urgent") {
    setDocumentsToShow(array.filter((d) => d.urgent));
  } else if (filter === "today") {
    setDocumentsToShow(
      array.filter((d) => getPrintDate(d.issued) === getPrintDate(today))
    );
  } else if (filter === "week") {
    if (today.getDay() === 0) {
      setDocumentsToShow(
        array.filter((d) => getPrintDate(d.issued) === getPrintDate(today))
      );
      return;
    }
    const startOfCurrentWeek = new Date(today);
    startOfCurrentWeek.setDate(today.getDate() - today.getDay());

    // Calculate the start of the last week (Sunday)
    const startOfLastWeek = new Date(startOfCurrentWeek);
    startOfLastWeek.setDate(startOfCurrentWeek.getDate() - 7);

    setDocumentsToShow(
      array.filter((d) => d.issued >= startOfLastWeek && d.issued <= today)
    );
  } else if (filter === "month") {
    const startOfCurrentMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    );
    setDocumentsToShow(array.filter((d) => d.issued >= startOfCurrentMonth));
  } else {
    setDocumentsToShow(array);
  }
};

export function replaceVariables(text: string, userData: ITherapist): string {
  if (!text || !userData) return "";
  const userName = userData.firstName;
  const userGender = userData.genderId;
  // Replace @name with user name
  let replacedString = text.replace(/(@name|name@)/gi, userName);
  if (text.toLowerCase().includes("gender")) {
    replacedString = replacedString.replace(
      /(@gender_know|gender_know@)/gi,
      userGender === "נקבה" ? "יודעת" : "יודע"
    );
    replacedString = replacedString.replace(
      /(@gender_ready|gender_ready@)/gi,
      userGender === "נקבה" ? "מוכנה" : "מוכן"
    );
    replacedString = replacedString.replace(
      /(@gender|gender@)/gi,
      userGender === "נקבה" ? "את" : "אתה"
    );
  }

  return replacedString;
}
export function isTTherapistWithUsersArray(
  documents: any
): documents is TTherapistWithUsers[] {
  return (
    Array.isArray(documents) &&
    documents.every((item) => "TherapistsId" in item)
  );
}
export function isToday(dateString: string): boolean {
  const today = new Date();
  const date = new Date(dateString);

  return (
    today.getDate() === date.getDate() &&
    today.getMonth() === date.getMonth() &&
    today.getFullYear() === date.getFullYear()
  );
}

export function isThisWeek(dateString: string): boolean {
  const today = new Date();
  const date = new Date(dateString);

  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay());

  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  return date >= startOfWeek && date <= endOfWeek;
}

export function parseConnectionMessage(message: string): IUserStatus {
  // Regular expression to match the user ID and action (left or joined)
  const regex = /User (\d+) (left|joined) the room\./;
  const match = message.match(regex);

  if (match) {
    const userId = parseInt(match[1], 10);
    const connected = match[2] === "joined";

    return {
      userId,
      connected,
    };
  }

  // Return null or throw an error if the message format is incorrect
  throw new Error("Invalid message format");
}

export function parseQuestionAnswerString(
  jsonString: string
): Record<string, string> | undefined {
  // Parse the JSON string into an array of objects
  const questionAnswerArray: IQuestionAnswer[] = JSON.parse(jsonString);

  if (!questionAnswerArray) {
    return undefined;
  }
  // Initialize the result object
  const result: Record<string, string> = {};

  // Iterate through the array and build the result object
  questionAnswerArray.forEach((item) => {
    result[item.Question] = item.Answer.trim();
  });

  return result;
}
// Function to filter the appointments and remove duplicates based on the highest ID
export const filterAppointments = (
  appointments: IChatAppointment[]
): IChatAppointment[] => {
  // Map to keep track of the highest ID appointment for each user
  const uniqueAppointmentsMap = new Map<number, IChatAppointment>();

  appointments.forEach((appointment) => {
    const existingAppointment = uniqueAppointmentsMap.get(appointment.UsersId);
    if (!existingAppointment || appointment.Id > existingAppointment.Id) {
      uniqueAppointmentsMap.set(appointment.UsersId, appointment);
    }
  });

  // Convert the map values to an array of IChatAppointment
  return Array.from(uniqueAppointmentsMap.values());
};

export function getAppointmentsMessages(
  appointments: IChatAppointment[]
): { userId: number; messages: { date: string; messages: IMessage[] }[] }[] {
  const groupedAppointments: { [key: string]: IMessage[] } = {};

  appointments.forEach((appointment) => {
    const storedMessagesString = sessionStorage.getItem(String(appointment.Id));

    let messages: IMessage[];
    try {
      messages = appointment.AppointmentReport
        ? JSON.parse(appointment.AppointmentReport)
        : [];
      if (!Array.isArray(messages)) {
        messages = [];
      }
      //add messages from the sessionStorage
      const storedMessages = storedMessagesString
        ? JSON.parse(storedMessagesString)
        : [];
      messages = [...messages, ...storedMessages];
    } catch (error) {
      console.error(
        `Error parsing comments for appointment ID ${appointment.Id}:`,
        error
      );
      messages = [];
    }

    if (!groupedAppointments[appointment.UsersId]) {
      groupedAppointments[appointment.UsersId] = [];
    }
    groupedAppointments[appointment.UsersId].push(...messages);
  });

  return Object.keys(groupedAppointments).map((key: string) => ({
    userId: Number(key),
    messages:
      groupedAppointments[Number(key)].length > 0
        ? sortMessagesByDate(groupedAppointments[Number(key)])
        : [],
  }));
}

export function sortMessagesByDate(
  messages: IMessage[]
): { date: string; messages: IMessage[] }[] {
  // sort messages by date
  // Temporary storage for grouped messages
  const groupedByDate: { [key: string]: IMessage[] } = {};

  // Group messages by date (ignoring time)
  messages.forEach((m) => {
    const date = formatDateTime(new Date(m.date)).split("T")[0]; // Extract the date part (YYYY-MM-DD)
    if (!groupedByDate[date]) {
      groupedByDate[date] = [];
    }
    groupedByDate[date].push(m);
  });

  // Convert grouped messages to the desired format and sort by date
  const groupedMessages = Object.keys(groupedByDate).map((date) => ({
    date,
    messages: groupedByDate[date],
  }));

  // Sort the grouped messages by date
  groupedMessages.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  return groupedMessages;
}

export function mergeUsersWithAppointments(
  users: {
    userId: number;
    messages: { date: string; messages: IMessage[] }[];
  }[],
  appointments: IChatAppointment[]
): {
  userId: number;
  messages: { date: string; messages: IMessage[] }[];
  appointment?: IChatAppointment;
}[] {
  // Create a map to store appointments by userId
  if (appointments.length === 0) {
    return users;
  }
  const appointmentsMap: { [key: number]: IChatAppointment | undefined } = {};

  // Populate the map with the first appointment found for each userId
  appointments.forEach((appointment) => {
    if (!appointmentsMap[appointment.UsersId]) {
      appointmentsMap[appointment.UsersId] = appointment;
    }
  });

  // Merge users with their corresponding appointment
  return users.map((user) => ({
    ...user,
    appointment: appointmentsMap[user.userId],
  }));
}
export const toggleMuteMic = (
  mediaStream: MediaStream | undefined,
  setIsMicMuted: (a: boolean) => void
) => {
  if (mediaStream) {
    const audioTrack = mediaStream.getAudioTracks()[0];
    if (audioTrack) {
      audioTrack.enabled = !audioTrack.enabled;
      setIsMicMuted(!audioTrack.enabled); // Update state to reflect mute/unmute
    }
  }
};

export const toggleVideo = (
  mediaStream: MediaStream | undefined,
  setIsVideoEnabled: Dispatch<SetStateAction<boolean>>
) => {
  if (mediaStream) {
    const videoTracks = mediaStream.getVideoTracks();
    if (videoTracks.length > 0) {
      // Toggle track.enabled (do not stop the track)
      const isEnabled = videoTracks[0].enabled;
      videoTracks.forEach((track) => {
        if (track.readyState === "live") {
          track.enabled = !track.enabled;
        }
      });
      setIsVideoEnabled(!isEnabled);
    }
  }
};
