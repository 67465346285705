import styles from "../../pages/questionReports/questionReportsPage.module.css";
import React, { useState } from "react";
import {
  useAddChatAppointmentMutation,
  useLazyGetChatAppointmentsQuery,
} from "../../services/api/user.api";
import Modal from "../modals/modal/Modal";
import ChatAppointmentSuccessPopup from "../modals/chatAppountmentSuccessPopup/chatAppointmentSuccessPopup";
import ErrorPopup from "../modals/errorPopup/errorPopup";
import { formatDateTime } from "../../utils/constants";

interface IAppointmentButtonProps {
  userId: string;
  therapistId: string;
  media: string;
  name: string;
}
const AppointmentButton = ({
  userId,
  therapistId,
  media = "1",
  name = "הזמן צ'אט",
}: IAppointmentButtonProps) => {
  const [addChatAppointment] = useAddChatAppointmentMutation();
  const [getAllAppointments] = useLazyGetChatAppointmentsQuery();

  const [openAppointmentModalSuccess, setOpenAppointmentModalSuccess] =
    useState(false);
  const [openModalError, setOpenModalError] = useState(false);

  const postAppointment = () => {
    addChatAppointment({
      UsersId: userId,
      TherapistsId: therapistId,
      AppointmentDate: formatDateTime(new Date()),
      MediaId: media,
    })
      .then((res) => {
        if ("data" in res) {
          setOpenAppointmentModalSuccess(true);
        } else {
          setOpenModalError(true);
        }
      })
      .finally(() => {
        getAllAppointments(therapistId);
      });
  };
  return (
    <>
      <button
        type={"button"}
        className={`${styles.btn} ${styles.btn_chat} hover`}
        onClick={postAppointment}
      >
        {name}
      </button>
      {openAppointmentModalSuccess && (
        <Modal onClose={() => setOpenAppointmentModalSuccess(false)}>
          <ChatAppointmentSuccessPopup
            onClose={() => setOpenAppointmentModalSuccess(false)}
          />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={() => setOpenModalError(false)}>
          <ErrorPopup onClose={() => setOpenModalError(false)} />
        </Modal>
      )}
    </>
  );
};

export default AppointmentButton;
