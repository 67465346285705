// src/slices/chatSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HubConnection } from "@microsoft/signalr";
import { IChatMessage, IUserChatData } from "../types";
import { IUser } from "../../types/types";
import { userApi } from "../api/user.api";
import { IChatAppointment } from "../types/user.types";
import { filterAppointments } from "../../utils/utils";

interface IChatState {
  isConnected: boolean;
  connection?: HubConnection;
  chatUsers: IUserChatData[];
  showUserJoinedModal: undefined | IUser;
  callEnded: undefined | string;
}

const initialState: IChatState = {
  isConnected: false,
  chatUsers: [],
  callEnded: undefined,
  showUserJoinedModal: undefined,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setWsConnection(state, action: PayloadAction<HubConnection>) {
      state.connection = action.payload;
    },
    setConnected(state, action: PayloadAction<boolean>) {
      state.isConnected = action.payload;
    },
    setChatUsers(state, { payload }: { payload: IUserChatData[] }) {
      console.log(payload);
      state.chatUsers = payload;
    },
    endCall(state, { payload }: { payload: string | undefined }) {
      return {
        ...state,
        callEnded: payload,
      };
    },
    updateChatData(
      state,
      {
        payload,
      }: { payload: [{ online: boolean; userId: number; messages?: [] }] }
    ) {
      return {
        ...state,
        chatUsers: state.chatUsers.map((u) => {
          const user = payload.find((us) => us.userId === u.userId);
          return {
            ...u,
            online: !!user?.online,
            messages: user?.messages || u.messages,
          };
        }),
      };
    },
    addMessage(state, { payload }: { payload: IChatMessage }) {
      state.chatUsers = state.chatUsers.map((u) =>
        u.userId === payload.senderId || u.userId === payload.receiverId
          ? { ...u, messages: [...u.messages, payload] }
          : u
      );
    },
    setUserTyping(state, { payload }: { payload: IChatMessage }) {
      return {
        ...state,
        chatUsers: state.chatUsers.map((usr) =>
          usr.userId === payload.senderId
            ? {
                ...usr,
                isTyping: payload.content.split(":")[1] === "1",
              }
            : usr
        ),
      };
    },
    setUserConnected(state, { payload }: { payload: number }) {
      return {
        ...state,
        chatUsers: state.chatUsers.map((usr) =>
          usr.userId === payload ? { ...usr, online: true } : usr
        ),
      };
    },
    setUserDisconnected(state, { payload }: { payload: number }) {
      return {
        ...state,
        chatUsers: state.chatUsers.map((usr) =>
          usr.userId === payload ? { ...usr, online: false } : usr
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getChatAppointments.matchFulfilled,
      (
        state,
        { payload }: { payload: { Appointments: IChatAppointment[] } }
      ) => {
        const filteredAppointments = filterAppointments(payload.Appointments);
        state.chatUsers = filteredAppointments.map((a: IChatAppointment) => ({
          userId: a.UsersId,
          fullName: a.FullName,
          online: false,
          isTyping: false,
          messages: [],
          appointment: a,
        }));
      }
    );
  },
});
export const { actions } = chatSlice;
export const {
  setWsConnection,
  setConnected,
  endCall,
  addMessage,
  setUserTyping,
  updateChatData,
  setUserConnected,
  setUserDisconnected,
  setChatUsers,
} = chatSlice.actions;
export default chatSlice.reducer;
