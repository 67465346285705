import styles from "./chatNote.module.css";
import { chatNoteTypes } from "../../utils/constants";
import { TChatNoteType } from "../../types/types";

function ChatNote({
  name,
  text,
  type,
}: {
  name: string;
  text?: string;
  type: TChatNoteType;
}) {
  return (
    <div className={styles.note}>
      {type === chatNoteTypes.isTyping ? (
        <>
          <span>{name}</span>
          <span>מקליד/ה</span>
          <span className={styles.loader} />
        </>
      ) : (
        <span>{text || ""}</span>
      )}
    </div>
  );
}

export default ChatNote;
